import { useTheme } from 'next-themes'
import { Toaster as Sonner } from 'sonner'

type ToasterProps = React.ComponentProps<typeof Sonner>

const Toaster = ({ ...props }: ToasterProps) => {
	const { theme = 'system' } = useTheme()

	return (
		<Sonner
			theme={theme as ToasterProps['theme']}
			className="toaster group"
			toastOptions={{
				classNames: {
					toast:
						'group toast group-[.toaster]:bg-background group-[.toaster]:text-text-primary group-[.toaster]:border-border group-[.toaster]:shadow-lg group-[.toaster]:text-base',
					description: 'group-[.toast]:text-muted-foreground',
					actionButton:
						'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
					cancelButton:
						'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
					closeButton:
						'group-[.toast]:bg-background group-[.toast]:text-text-primary group-[.toast]:border-muted-foreground',
				},
				/**
				 * Around 500ms per word
				 * @see https://bootcamp.uxdesign.cc/toast-notifications-how-to-make-it-efficient-400cab6026e9
				 */
				duration: 4500,
				closeButton: true,
			}}
			{...props}
		/>
	)
}

export { Toaster }
