import React from 'react'
import ReactDOM from 'react-dom/client'

// Import Poppins font and ship at build time
import '@fontsource/poppins'

import App from './App.tsx'
// Import env here so we can check if some is missing at build time
import './env.js'
// Import global styles
import './index.css'

ReactDOM.createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
)
