import { memo, useState, useTransition } from 'react'
import { getRouteApi } from '@tanstack/react-router'

import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select'

import { TabItem } from './TabItem'

const route = getRouteApi('/_dashboard/overview')

type Tabs =
	| 'alerts'
	| 'remediation-actions'
	| 'actions-activity'
	| 'enabled-alerts'

const TabsMenu = () => {
	const navigate = route.useNavigate()

	/**
	 * Use React's startTransition when switching tabs
	 * This will interrupt the current transition and start a new one to
	 * immediately render the new tab
	 */
	const [_, startTransition] = useTransition()

	const [tab, setTab] = useState<Tabs>('alerts')

	const handleTabChange = (tab: Tabs) => {
		setTab(tab)

		// Navigate with startTransition
		startTransition(() => {
			navigate({
				to: `/overview/${tab}`,
			})
		})
	}

	return (
		<div className="mb-12">
			{/* Desktop */}
			<ul className="hidden h-10 items-center justify-center rounded-md bg-muted p-1 text-muted-foreground sm:inline-flex">
				<TabItem
					to="/overview/alerts"
					onClick={() => handleTabChange('alerts')}
				>
					Alerts
				</TabItem>
				<TabItem
					to="/overview/remediation-actions"
					onClick={() => handleTabChange('remediation-actions')}
				>
					Remediation Actions
				</TabItem>
				<TabItem
					to="/overview/actions-activity"
					onClick={() => handleTabChange('actions-activity')}
				>
					Actions Activity
				</TabItem>
				<TabItem
					to="/overview/enabled-alerts"
					onClick={() => handleTabChange('enabled-alerts')}
				>
					Enabled Alerts
				</TabItem>
			</ul>

			{/* Mobile */}
			<div className="flex sm:hidden">
				<Select value={tab} onValueChange={handleTabChange}>
					<SelectTrigger>
						<SelectValue placeholder="Select Tab" />
					</SelectTrigger>
					<SelectContent>
						<SelectItem value="alerts">Alerts</SelectItem>
						<SelectItem value="remediation-actions">
							Remediation Actions
						</SelectItem>
						<SelectItem value="actions-activity">Actions Activity</SelectItem>
						<SelectItem value="enabled-alerts">Enabled Alerts</SelectItem>
					</SelectContent>
				</Select>
			</div>
		</div>
	)
}

export const OverviewTabs = memo(TabsMenu)
