import { ScrollRestoration } from '@tanstack/react-router'

import { cn } from '@/lib/utils'

import { ScrollArea } from '../ui/scroll-area'

type SectionProps = {
	className?: string
	children: React.ReactNode
}
export const Section = ({ children, className }: SectionProps) => {
	return (
		<div className="h-full w-full">
			<div
				className={cn(
					'flex h-full flex-col items-start justify-start',
					className,
				)}
			>
				<ScrollArea className="w-full">
					<ScrollRestoration />
					<div className="p-6">{children}</div>
				</ScrollArea>
			</div>
		</div>
	)
}

export const SectionTitle = ({
	children,
	className,
}: {
	children: React.ReactNode
	className?: string
}) => {
	return (
		<h1 className={cn('mb-8 text-3xl font-bold text-text-primary', className)}>
			{children}
		</h1>
	)
}
