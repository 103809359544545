import { useLocation } from '@tanstack/react-router'

/**
 * Custom hook to get the breadcrumbs from the current location
 * @returns {[{name: string, path: string}]} - An array of objects representing the breadcrumbs
 * @see https://github.com/TanStack/router/discussions/962#discussioncomment-10231831
 */
export function useTSRBreadcrumbs() {
	const current = useLocation()

	const route_history = current.pathname
		.split('/')
		.filter((x) => x && x.length > 0)

	const breadcrumb_routes = route_history.reduce(
		(acc: { name: string; path: string }[], route) => {
			const prevPath = acc[acc.length - 1]?.path ?? ''
			const parsedName = route
				.split('-')
				.map((x) => x.charAt(0).toUpperCase() + x.slice(1))
				.join(' ')

			acc.push({ name: parsedName, path: `${prevPath}/${route}` })
			return acc
		},
		[],
	)
	return { breadcrumb_routes }
}
